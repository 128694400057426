import React from 'react';
import { Notification } from 'shared-library';

import {
  InvoiceSummary as InvoiceSummaryComponent,
  PaymentContextProvider,
} from 'packages/payment';
import { Switch, Route } from 'react-router';
import { StudyContextProvider, BookingContextProvider } from 'packages/booking-contexts';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

const InvoiceSummary = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/admin/study/:studyId/details">
        <BookingContextProvider>
          <StudyContextProvider>
            <PaymentContextProvider>
              <ToastProvider
                autoDismiss
                components={{ Toast: Notification }}
                placement="bottom-right"
              >
                <InvoiceSummaryComponent />
              </ToastProvider>
            </PaymentContextProvider>
          </StudyContextProvider>
        </BookingContextProvider>
      </Route>
    </Switch>
  </BrowserRouter>
);

export default InvoiceSummary;
